<template>
    <div class="grid">
        <div class="col-12 md:col-12">
            <div class="card p-fluid">
                <h5>{{ modalidadeTipoServico.id ? 'Atualizar Tipo Serviço' : 'Novo Tipo Serviço' }}</h5>
                <div class="field col-9">
                    <label class="required">Título</label>
                    <InputText type="text" v-model="modalidadeTipoServico.title" />
                </div>
                <div class="field col-9">
                    <label class="required">Modalidade Sas</label>
                    <Dropdown v-model="modalidadeTipoServico.codigoSas" :options="modalidadeSas" optionValue="ID" optionLabel="Nome" showClear />
                </div>
                <div class="field col-12 md:col-3">
                    <label for="currency-br">Limite Valores</label>
                    <InputNumber id="currency-br" v-model="modalidadeTipoServico.limiteValores" mode="currency" currency="BRL" locale="pt-BR" />
                </div>
                <div class="field col-12 md:col-3">
                    <label for="minmax-buttons">Limite Quantidade Ano</label>
                    <InputNumber id="minmax-buttons" v-model="modalidadeTipoServico.limiteQuantidadeAno" mode="decimal" showButtons :min="0" :max="100" />
                </div>
                <div class="field-checkbox col-10">
                    <Checkbox id="binary" v-model="modalidadeTipoServico.active" :binary="true" />
                    <label for="binary">Ativo</label>
                </div>
            </div>
            <Button label="Cancelar" @click.stop="cancelar" icon="pi pi-times-circle" class="mr-2 p-button-secondary"></Button>
            <Button
                label="Salvar"
                @click.stop="inserir"
                icon="pi pi-check-circle"
                :disabled="!modalidadeTipoServico.title || !modalidadeTipoServico.codigoSas"
            ></Button>
        </div>
    </div>
</template>

<script>
import Services from './service';

export default {
    data() {
        return {
            modalidadeTipoServico: {
                title: null,
                limiteValores: null,
                limiteQuantidadeAno: null,
                active: true,
                codigoSas: null,
                descricaoSas: null,
            },
            modalidadeSas: [],
        };
    },
    mounted() {
        if (this.$route.params.id) {
            Services.obterPorId(this.$route.params.id).then((response) => {
                if (response?.success) {
                    this.modalidadeTipoServico = response.data;
                }
            });
        }
        this.obterModalidadeSas();
    },
    methods: {
        inserir() {
            if (this.modalidadeTipoServico.id) {
                Services.atualizar(this.modalidadeTipoServico.id, this.modalidadeTipoServico).then((response) => {
                    this.respostaSalvar(response, true);
                });
            } else {
                this.$store.dispatch('addRequest');
                Services.inserir(this.modalidadeTipoServico).then((response) => {
                    this.respostaSalvar(response, false);
                });
            }
        },
        respostaSalvar(response, edicao) {
            if (response?.success) {
                this.$toast.add({
                    severity: 'success',
                    summary: 'Tipo de Serviço',
                    detail: `Tipo de Serviço ${edicao ? 'editado' : 'inserido'} com sucesso`,
                    life: 3000,
                });

                this.cancelar();
            } else {
                this.$toast.add({
                    severity: 'error',
                    summary: 'Erro',
                    detail: response.errors[0],
                    life: 10000,
                });
            }
            this.$store.dispatch('removeRequest');
        },
        obterModalidadeSas() {
            this.$store.dispatch('addRequest');
            Services.obterModalidadeSas().then((response) => {
                if (response?.success) {
                    this.modalidadeSas = response.data;
                } else {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Erro',
                        detail: response.errors[0],
                        life: 10000,
                    });
                    let modalidadeDto = {
                        ID: this.modalidadeTipoServico.codigoSas,
                        Nome: this.modalidadeTipoServico.descricaoSas,
                    };
                    this.modalidadeSas.push(modalidadeDto);
                }
                this.$store.dispatch('removeRequest');
            });
        },
        cancelar() {
            this.$router.push({
                name: `ModalidadeTipoServico`,
            });
        },
    },
    watch: {
        'modalidadeTipoServico.codigoSas'() {
            if (this.modalidadeTipoServico.codigoSas > 0 && this.modalidadeSas.length > 0) {
                const result = this.modalidadeSas.filter((modalidade) => modalidade.ID == this.modalidadeTipoServico.codigoSas);
                this.modalidadeTipoServico.descricaoSas = result[0].Nome;
                this.modalidadeTipoServico.codigoSas = result[0].ID;
            }
        },
    },
};
</script>
